import React, { FC, Fragment } from 'react';
import { Link } from 'gatsby';
import Badge from '@material-ui/core/Badge';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import styled from '@emotion/styled';
import { INavComponent } from '@src/provider/provider';
import { INavItem, INavbarData } from './header';

const Menu = styled(MuiMenu)`
    .menu-item {
        padding: ${(props) => props.theme.spacing(2)}px;
    }
`;

type IProps = {
    navBarData: INavComponent[];
    navItem: INavItem;
    currentData: INavComponent;
    anchorEl: Element | ((element: Element) => Element);
    handleCloseMenu: () => void;
};

const NavBarMenu: FC<IProps> = ({ navBarData, navItem, anchorEl, handleCloseMenu, currentData }) => {
    const hasChildData = (item: INavComponent) => {
        const limit: number = currentData?.props?.limit || navItem[item.id]?.length;
        return navItem[item.id]?.slice(0, limit);
    };

    return (
        <Fragment>
            {navBarData?.map((item) => {
                return (
                    <Menu
                        id={item.id}
                        key={item.id}
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        open={item.id === currentData.id}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        TransitionComponent={Fade}
                        onClose={handleCloseMenu}
                        MenuListProps={{ onMouseLeave: handleCloseMenu }}
                    >
                        {hasChildData(item)?.map((nav: INavbarData) => {
                            return (
                                <Link key={nav.name} to={`${nav.slug}/`} onClick={handleCloseMenu}>
                                    <MenuItem className="menu-item">
                                        {currentData?.props?.showCount ? (
                                            <Badge badgeContent={nav.count} color="secondary">
                                                {nav.name}
                                            </Badge>
                                        ) : (
                                            nav.name
                                        )}
                                    </MenuItem>
                                </Link>
                            );
                        })}
                    </Menu>
                );
            })}
        </Fragment>
    );
};

export default NavBarMenu;
