/** @jsx jsx */
import { FC } from 'react';
import { css, jsx } from '@emotion/react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

type IProps = {
    className?: string;
};
const GoBack: FC<IProps> = ({ className }) => {
    const handleClick = () => {
        window.history.go(-1);
    };

    return (
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick} className={className}>
            <KeyboardArrowLeftIcon
                css={css`
                    font-size: 2rem;
                `}
            />
        </IconButton>
    );
};

export default GoBack;
