import React, { FC, useContext, useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import MobileStepper from '@material-ui/core/MobileStepper';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from '@emotion/styled';
import { GlobalContext } from '@src/provider';
import { localStorageUtility } from '@src/util';
import { Slides } from '@components';

const Dialog = styled(MuiDialog)`
    .title {
        text-align: center;
    }
    .content {
        ${(props) => props.theme.breakpoints.up('sm')} {
        }
    }

    .slides {
        padding-bottom: 70%;
        margin-bottom: ${(props) => props.theme.spacing(2)}px;
    }

    .action {
        display: flex;
        justify-content: center;
    }
`;

const Announcement: FC = () => {
    const { allFile } = useStaticQuery<Typing.Query>(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "images/activity" } }) {
                edges {
                    node {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                            id
                        }
                    }
                }
            }
        }
    `);

    const { state, dispatch } = useContext(GlobalContext);
    const { announcementData } = state;
    const { title, enabled, content, action, preLocalStorageKey, currentLocalStorageKey } = announcementData;

    useEffect(() => {
        if (localStorageUtility.getItem(preLocalStorageKey)) {
            localStorageUtility.removeItem(preLocalStorageKey);
        }
    }, []);

    const mappingOpenLogic = () => {
        return enabled && localStorageUtility.getItem(currentLocalStorageKey) !== 'true';
    };

    const mappingCheckedLogic = () => {
        return localStorageUtility.getItem(currentLocalStorageKey) === 'true';
    };

    const [open, setOpen] = useState<boolean>(mappingOpenLogic());
    const [checked, setChecked] = useState<boolean>(mappingCheckedLogic());

    const handleClose = () => {
        localStorageUtility.setItem(currentLocalStorageKey, checked.toString());
        setOpen(false);
        if (checked) {
            dispatch({ type: 'SET_ANNOUNCEMENT', payload: false });
        }
    };

    const handleCancel = () => {
        handleClose();
    };

    const handleConfirm = () => {
        if (typeof window !== `undefined` && action.confirm?.props?.redirectUrl) {
            window.open(action.confirm.props.redirectUrl);
        }
        handleClose();
    };

    const handleChangeChecked = () => {
        setChecked(!checked);
    };

    const list = allFile.edges.map(({ node }) => ({
        id: node.id,
        fluid: node.childImageSharp.fluid,
    }));

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle id="alert-dialog-title" className="title">
                {title}
            </DialogTitle>
            <DialogContent className="content">
                <Slides data={list} className="slides" />
                <div>
                    <FormControlLabel
                        control={<Checkbox checked={checked} color="primary" onChange={handleChangeChecked} />}
                        label="不要再顯示此訊息"
                    />
                </div>
                <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>
            <DialogActions className="action">
                <Button variant="outlined" onClick={handleCancel} color="primary">
                    {action.cancel.btnText}
                </Button>
                <Button variant="contained" onClick={handleConfirm} color="primary">
                    {action.confirm.btnText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Announcement;
