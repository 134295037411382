import React, { FC } from 'react';
import { Tree } from '@components';
import { ITableOfContents } from './blogToolbar';

type IProps = {
    tableOfContents: ITableOfContents;
    onClickTableContent?: () => void;
    onClose?: () => void;
};
const TableOfContent: FC<IProps> = ({ onClickTableContent, tableOfContents }) => {
    return (
        <Tree defaultOpen name="TABLE OF CONTENT">
            {tableOfContents.items.map((tocItem) => {
                if (tocItem?.items && tocItem.items.length > 0) {
                    return (
                        <Tree name={tocItem.title} href={tocItem.url} key={tocItem.title} onClick={onClickTableContent}>
                            {tocItem.items.map((tocItemChild) => {
                                return (
                                    <Tree
                                        name={tocItemChild.title}
                                        href={tocItemChild.url}
                                        key={tocItemChild.title}
                                        onClick={onClickTableContent}
                                    />
                                );
                            })}
                        </Tree>
                    );
                }
                return (
                    <Tree
                        defaultOpen
                        name={tocItem.title}
                        href={tocItem.url}
                        key={tocItem.title}
                        onClick={onClickTableContent}
                    />
                );
            })}
        </Tree>
    );
};

export default TableOfContent;
