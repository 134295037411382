import React, { FC } from 'react';
import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

//

// const Logo = (props) => {
//     return (
//         <svg {...props}>

//         </svg>
//     );
// };

const LogoIcon = createSvgIcon(
    <path d="M47 1.6C36.9 3.7 25.5 9.9 17.9 17.3 6.6 28.3 1.2 40.1.3 56.2c-.7 12.5.7 20.1 6 30.7 7.9 16.2 22.8 27.7 41 31.8 4 .9 7.4 1.7 7.6 1.9.2.2-2.7 5-6.4 10.6-7.6 11.4-9.9 17.4-9 23.1 1.2 8.2 7.1 15.6 13.7 17.2 2.6.7 3.5-.5 25.9-34.2 12.8-19.1 25-37 27.2-39.7 9.3-11.9 13.3-24.8 12.4-40.8-.6-12.1-3-19.8-8.9-29-5.7-9-13.9-16.2-23.3-20.9C73.3.4 60.5-1.3 47 1.6zm27.3 32.2c9.9 5.5 16.7 18.6 15.2 29.1-1.6 11.9-10.6 22.5-21.7 25.6-11 3.1-20.4.7-28.8-7.1-9.7-9.1-12.4-23.2-6.5-34.8 8-15.7 26.5-21.4 41.8-12.8zM127 20v13h20.1l-.2 61.9-.1 61.9 2.5 5.6c3.6 7.8 6.8 9.6 17.5 9.6h8.2V7h-48v13zM260.2 7.9c-5.3.9-9.2 3.7-11.4 8.2-1 1.9-5.6 13.5-10.2 25.9-4.7 12.4-8.8 23.3-9.2 24.4-.6 1.7-1.6 1.8-13.3 1.2-6.9-.4-13.8-.9-15.3-1.2-2.7-.5-2.8-.4-2.8 4.1 0 10.3 6 30.6 11.3 38.5l2.4 3.5-6.2 16c-3.4 8.8-8.3 21.6-10.9 28.5-2.7 6.9-5.1 13.1-5.3 13.8-.4.9 3.5 1.2 17.4 1.2h17.9l1.3-3.8c1.3-3.8 10.8-30.1 12.2-33.8.7-2 .9-2.1 7.1-.8 12 2.5 29.5 1 45-3.9l7.3-2.3 8.4 22.3 8.3 22.3h36.6l-3.8-9.8c-2.1-5.3-5.9-15.1-8.5-21.7-6.4-16.4-13.7-34.9-23.8-60.5-4.7-11.8-12.4-31.4-17.2-43.5-4.7-12.1-9.4-22.8-10.3-23.8-.9-1.1-2.9-2.5-4.3-3.3-3.2-1.6-16.8-2.6-22.7-1.5zm15.2 59.8c3 8.2 7.5 20.2 10 26.8 2.5 6.6 4.6 12.3 4.6 12.6 0 1.4-13.4 3.1-19.8 2.7-7-.5-15.9-3.8-18.6-6.7-1.5-1.6-.9-3.8 7.6-27.4 6.2-17.3 9.5-25.2 10-24.2.5.8 3.3 8.1 6.2 16.2zM361 89.9V172h6c3.3 0 7.5-.6 9.4-1.4 4.9-2.1 10.5-7.4 13.2-12.7 2.3-4.7 2.4-5.5 2.4-27.4V108h6.3c12.3-.1 38.3-2 42.2-3.1 19.7-5.4 32-20.6 33.9-41.9 2.5-27-12.1-48.8-35.6-53.1-2.9-.5-21.6-1.2-41.5-1.6L361 7.7v82.2zm66.5-51.5C444.2 45.6 445.6 68 430 76c-2.9 1.5-7.5 2.1-20.1 3-9 .5-16.7 1-17.1 1-.5 0-.8-10-.8-22.1V35.8l15.8.4c11.9.3 16.7.9 19.7 2.2zM485.7 8.7c-.4.3-.7 37.2-.7 82V172h6.5c9.8 0 15.7-3.5 21.6-12.8l2.9-4.6V108h6.3c13.5-.1 38.5-2 43.3-3.4C586.4 99 599 81.2 599 57.5c0-23-10.8-39.7-29.6-45.9-2.8-.9-10.9-2.1-18-2.6-14.8-1.1-64.7-1.3-65.7-.3zm62 28.6c3.5.8 7.6 2.5 9.6 4 6.9 5.2 9.6 16.1 6.2 24.4C559 76.5 549.4 80 524.8 80H516V36h12.8c7 0 15.5.6 18.9 1.3z" />,
    'Logo'
);

export default (props) => <LogoIcon viewBox="0 0 599 173" {...props} />;
