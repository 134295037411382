import React, { FC, useContext } from 'react';
import { GlobalContext } from '@src/provider';

type IProps = {
    href: string;
};

const FaceBookShareAndLike: FC<IProps> = ({ href }) => {
    const { state } = useContext(GlobalContext);
    const { facebookPlugin } = state;
    const { size, layout, enabled, facebookId } = facebookPlugin;
    const encodeHref = encodeURIComponent(href);
    const src = `https://www.facebook.com/plugins/like.php?href=${encodeHref}&width=450&layout=${layout}&action=like&size=${size}&share=true&height=35&appId=${facebookId}`;

    if (!enabled) {
        return null;
    }
    return (
        <iframe
            src={src}
            width="450"
            height="35"
            style={{ border: 'none', overflow: 'hidden' }}
            scrolling="no"
            frameBorder="0"
            allow="encrypted-media"
        />
    );
};

export default FaceBookShareAndLike;
