import React, { FC } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { primaryTheme } from '@theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { default as Announcement } from '@src/components/Announcement';
import { default as LoadingBar } from '@src/components/LoadingBar';
import { default as Header } from './Header';
import { default as Footer } from './Footer';
import { default as ScrollToTop } from './ScrollToTop';
import { default as SEO } from './SEO';
import { default as GlobalStyle } from './GlobalStyle';

const StyleWrapper = styled.div<{ isLandingPage: boolean }>`
    .main {
        overflow: hidden;
        &__box {
            min-height: 100vh;
            /* margin-top: ${(props) => (props.isLandingPage ? '0px' : props.theme.spacing(20))}px; */
            margin-bottom: ${(props) => props.theme.spacing(20)}px;
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            flex-shrink: 0;
        }
    }
`;

type IProps = {
    children: React.ReactNode;
    window?: () => Window;
    title?: string;
    ogImage?: string;
    slug?: string;
    isLandingPage?: boolean;
};

const Layout: FC<IProps> = (props) => {
    const { children, title, isLandingPage, ogImage, slug } = props;
    // console.log(navigator.userAgent);
    return (
        <MuiThemeProvider theme={primaryTheme}>
            <ThemeProvider theme={primaryTheme}>
                <SEO title={title} ogImage={ogImage} slug={slug} />
                <CssBaseline />
                <GlobalStyle />
                <Header isLandingPage={isLandingPage} />
                <StyleWrapper isLandingPage={isLandingPage}>
                    <Container className="main">
                        <Box className="main__box">{children}</Box>
                    </Container>
                </StyleWrapper>
                <Footer />
                <ScrollToTop />
                <Announcement />
                {/* <LoadingBar open /> */}
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default Layout;
