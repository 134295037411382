import React, { FC, useState, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import styled from '@emotion/styled';
import { default as ArticleCard } from './ArticleCard';
import { IArticleData } from './Article';

const StyledWrapper = styled.div`
    margin-top: ${(props) => props.theme.spacing(10)}px;
    .pagination {
        &-top {
            margin-bottom: ${(props) => props.theme.spacing(10)}px;
        }

        &-bottom {
            margin-top: ${(props) => props.theme.spacing(10)}px;
        }
    }
`;

type IProps = {
    limit?: number;
    enablePagination?: boolean;
    articleList: IArticleData[];
    showAuthor: boolean;
};

const ArticleMaker: FC<IProps> = ({ articleList, limit, enablePagination, showAuthor }) => {
    const [page, setPage] = useState<number>(1);
    const total: number = articleList.length;
    const count: number = Math.ceil(total / limit);

    const mappingList = (): IArticleData[] => {
        if (!enablePagination && !limit) {
            return articleList;
        }

        if (!enablePagination && limit) {
            const result = articleList.slice(0, limit);
            return result;
        }

        if (enablePagination && limit) {
            const offset = (page - 1) * limit;
            const take = page * limit;
            const result = articleList.slice(offset, take);
            return result;
        }
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <StyledWrapper>
            <Grid item xs={12}>
                <Grid container justify="center" spacing={3}>
                    {mappingList()?.map((item) => (
                        <Grid key={item.title} item>
                            <ArticleCard articleData={item} showAuthor={showAuthor} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {enablePagination && (
                <Grid container justify="flex-end" className="pagination-bottom">
                    <Pagination
                        count={count}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            )}
        </StyledWrapper>
    );
};

export default ArticleMaker;
