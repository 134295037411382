import React, { FC } from 'react';
import { css, Global } from '@emotion/react';

const GlobalStyle: FC = () => {
    return (
        <Global
            styles={css`
                body {
                    overflow-x: hidden;
                    -webkit-overflow-scrolling: touch;
                    a {
                        text-decoration: none;
                        &:visited,
                        :link,
                        :active {
                            color: inherit;
                        }
                    }
                }
            `}
        />
    );
};

export default GlobalStyle;
