import React, { FC } from 'react';
import { navigate } from 'gatsby';
import Chip from '@material-ui/core/Chip';
import { removeEmptyOrSlash } from '@src/util';

type IProps = { tagName: string };

const Tag: FC<IProps> = ({ tagName }) => {
    const handleRedirectToTags = () => {
        navigate(`/tags/${removeEmptyOrSlash(tagName)}`);
    };
    return <Chip label={tagName} variant="outlined" color="primary" onClick={handleRedirectToTags} />;
};

export default Tag;
