import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { counter, removeEmptyOrSlash } from '@src/util';
import { INavItem, INavbarData } from './header';
import { default as SideBarDrawer } from './SideBarDrawer';
import { default as NavBar } from './NavBar';
import { default as GoBack } from './GoBack';
import { default as HeaderTitle } from './HeaderTitle';

const calculateNavWidth = (value: string): { title: number; navBar: number } => {
    const title: number = value === 'sm' ? 30 : 15;
    const navBar: number = 100 - title;

    return {
        title,
        navBar,
    };
};

const Container = styled.header<{ isTransparent: boolean; trigger: boolean }>`
    .app-bar {
        transition: all 0.5s ease-in-out;
        /* box-shadow: ${(props) => (props.trigger ? props.theme.shadows[4] : 'none')}; */
        box-shadow: none;
        ${({ theme, isTransparent }) => {
            if (!isTransparent && theme.palette.linearGradient) {
                return css`
                    color: ${theme.palette.common.white};
                    background: ${(!isTransparent && theme.palette.linearGradient) || null};
                `;
            }
            return css`
                background-color: ${isTransparent ? ' rgba(255, 255, 255, 0)' : theme.palette.background.default};
                color: ${isTransparent ? theme.palette.common.white : theme.palette.primary.main};
            `;
        }}
    }

    .tool-bar {
        padding-top: ${(props) => props.theme.spacing(4)}px;
        padding-bottom: ${(props) => props.theme.spacing(4)}px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title {
        margin-left: ${(props) => props.theme.spacing(2)}px;
        flex-grow: 0;
        flex-basis: 80%;
        max-width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        ${(props) => props.theme.breakpoints.up('sm')} {
            flex-basis: ${calculateNavWidth('sm').title}%;
            max-width: ${calculateNavWidth('sm').title}%;
        }
        ${(props) => props.theme.breakpoints.up('lg')} {
            flex-basis: ${calculateNavWidth('lg').title}%;
            max-width: ${calculateNavWidth('lg').title}%;
        }
    }

    .sidebar-icon {
        ${(props) => props.theme.breakpoints.up('sm')} {
            display: none;
        }
    }

    .nav-bar {
        display: none;
        ${(props) => props.theme.breakpoints.up('sm')} {
            display: block;
            flex-grow: 0;
            flex-basis: ${calculateNavWidth('sm').navBar}%;
            max-width: ${calculateNavWidth('sm').navBar}%;
        }
        ${(props) => props.theme.breakpoints.up('lg')} {
            display: block;
            flex-grow: 0;
            flex-basis: ${calculateNavWidth('lg').navBar}%;
            max-width: ${calculateNavWidth('lg').navBar}%;
        }
    }
`;

type IProps = {
    isLandingPage?: boolean;
};

const Header: FC<IProps> = ({ isLandingPage }) => {
    const siteData = useStaticQuery<Typing.Query>(graphql`
        query {
            allMdx(filter: { frontmatter: { published: { eq: true }, type: { eq: "blog" } } }) {
                edges {
                    node {
                        frontmatter {
                            tags
                            category
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    const trigger =
        typeof window !== `undefined`
            ? useScrollTrigger({
                  target: window,
                  disableHysteresis: true,
                  threshold: 0,
              })
            : false;

    const originCategoriesData = siteData.allMdx.edges.map(({ node }) => node.frontmatter.category);
    const originTagsData = siteData.allMdx.edges.map(({ node }) => node.frontmatter.tags);
    const categories: INavbarData[] = counter(originCategoriesData).map((item) => {
        return { name: item.key, count: item.value, slug: `/blog/${removeEmptyOrSlash(item.key)}` };
    });
    const tags: INavbarData[] = counter(_.flatten(originTagsData)).map((item) => {
        return { name: item.key, count: item.value, slug: `/tags/${removeEmptyOrSlash(item.key)}` };
    });

    const navItem: INavItem = {
        categories,
        tags,
    };

    return (
        <Container isTransparent={isLandingPage} trigger={trigger}>
            <AppBar position="sticky" className="app-bar" component="div">
                <Toolbar id="back-to-top-anchor" className="tool-bar">
                    {!isLandingPage && <GoBack />}
                    <HeaderTitle className="title" />
                    <NavBar navItem={navItem} className="nav-bar" />
                    <SideBarDrawer navItem={navItem} className="sidebar-icon" />
                </Toolbar>
            </AppBar>
        </Container>
    );
};

export default Header;
