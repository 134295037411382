import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import { default as ArticleCard } from './ArticleCard';
import { mappingMdxToArticleData } from '@src/util';

type IProps = {
    limit?: number;
};
const NewestArticle: FC<IProps> = (props) => {
    const { limit } = props;
    const { allMdx } = useStaticQuery<Typing.Query>(graphql`
        query {
            allMdx(
                filter: { frontmatter: { published: { eq: true }, type: { eq: "blog" } } }
                sort: { order: DESC, fields: [frontmatter___date] }
            ) {
                edges {
                    node {
                        id
                        excerpt
                        frontmatter {
                            date(formatString: "YYYY/MM/DD")
                            title
                            tags
                            category
                            bannerPhoto {
                                childImageSharp {
                                    fluid {
                                        src
                                        originalName
                                    }
                                }
                            }
                            authorPhoto {
                                childImageSharp {
                                    fluid {
                                        src
                                        originalName
                                    }
                                }
                            }
                            author
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    const data = mappingMdxToArticleData(allMdx.edges.slice(0, limit ? limit : allMdx.edges.length));

    return (
        <Grid container justify="center" spacing={3}>
            {data.map((item) => (
                <Grid key={item.title} item>
                    <ArticleCard articleData={item} />
                </Grid>
            ))}
        </Grid>
    );
};

export default NewestArticle;
