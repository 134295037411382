import React, { FC } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { getFileExtension } from '@src/util';
import { default as LimitLine } from '@src/components/LimitLine';
import { default as Tag } from '@src/components/Tag';
import { IArticleData } from './Article';

const StyledWrapper = styled.div`
    .card-container {
        width: 300px;
        box-shadow: ${(props) => props.theme.shadows[10]};
    }

    .card-media {
        &__img {
            height: 0;
            background-size: contain;
            padding-top: 60%;
            filter: blur(2px);
            cursor: pointer;
        }

        &__backdrop {
            background-color: rgba(0, 0, 0, 0.6);
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            color: white;
            padding-left: ${(props) => props.theme.spacing(2)}px;
            padding-right: ${(props) => props.theme.spacing(2)}px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__backdrop-date {
            font-weight: bold;
            position: absolute;
            bottom: 1px;
            right: ${(props) => props.theme.spacing(1)}px;
        }
    }

    .card-header {
        display: flex;
        align-items: center;
        flex-direction: column;
        &__avatar {
            position: relative;
            top: -${(props) => props.theme.spacing(5)}px;
            width: ${(props) => props.theme.spacing(12)}px;
            height: ${(props) => props.theme.spacing(12)}px;
        }
    }

    .card-action {
        min-height: ${(props) => props.theme.spacing(6)}px;
    }
`;

type IProps = {
    articleData: IArticleData;
    showAuthor?: boolean;
};

const ArticleCard: FC<IProps> = ({ articleData, showAuthor = false }) => {
    const handleRedirectToArticle = () => {
        trackCustomEvent({
            category: 'Article',
            action: 'Click',
            label: articleData.title,
        });
        if (getFileExtension(articleData.link)) {
            window.open(articleData.link, '-blank');
        } else {
            navigate(`/${articleData.link}/`);
        }
    };

    return (
        <StyledWrapper>
            <Card className="card-container">
                <CardActionArea onClick={handleRedirectToArticle}>
                    <CardMedia className="card-media__img" image={articleData.bannerPhoto} title="bannerPhoto" />
                    <div className="card-media__backdrop">
                        <Typography component="h2" variant="h6">
                            <LimitLine limitLines={2} fontSize="inherit" lineHeight="1.5">
                                {articleData.title}
                            </LimitLine>
                        </Typography>
                        <Typography component="h3" variant="body2" className="card-media__backdrop-date">
                            {articleData.date}
                        </Typography>
                    </div>
                </CardActionArea>
                {showAuthor && (
                    <div className="card-header">
                        <Avatar
                            className="card-header__avatar"
                            aria-label="recipe"
                            alt="authorPhoto"
                            src={articleData.authorPhoto || null}
                        />
                        <Typography component="h2" variant="h6">
                            {articleData.author}
                        </Typography>
                    </div>
                )}
                {articleData.content && (
                    <CardContent>
                        <Typography variant="body2" color="textPrimary" component="p">
                            <LimitLine limitLines={3} fontSize="inherit" lineHeight="1.5">
                                {articleData.content}
                            </LimitLine>
                        </Typography>
                    </CardContent>
                )}
                <CardActions className="card-action">
                    {articleData?.tags?.length > 0 && articleData.tags.map((tag) => <Tag key={tag} tagName={tag} />)}
                </CardActions>
            </Card>
        </StyledWrapper>
    );
};

export default ArticleCard;
