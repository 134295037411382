/** @jsx jsx */
import { FC } from 'react';
import { Link } from 'gatsby';
import { css, jsx } from '@emotion/react';
import Typography from '@material-ui/core/Typography';
import { default as LogoIcon } from '@src/components/icons/Logo';

type IProps = {
    className?: string;
};

const HeaderTitle: FC<IProps> = ({ className }) => {
    return (
        <Typography variant="h6" className={className}>
            <Link to="/">
                <LogoIcon
                    css={css`
                        width: 5rem;
                    `}
                />
                <span
                    css={css`
                        margin-left: 8px;
                        letter-spacing: 1px;
                        display: inline-block;
                        text-transform: uppercase;
                    `}
                >
                    Blog
                </span>
            </Link>
        </Typography>
    );
};

export default HeaderTitle;
