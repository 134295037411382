export type WithUnknownValue<T> = T | 'unknown';
export type IBrowserMode = WithUnknownValue<'mobile' | 'desktop'>;
export type IDeviceOS = WithUnknownValue<'Android' | 'iOS' | 'Bot'>;

class DeviceUtility {
    public getBrowserMode(): IBrowserMode {
        if (typeof navigator === 'undefined') {
            return 'unknown';
        }
        if (
            /android|webOS|iPhone|iPod|iOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(navigator.userAgent)
        ) {
            return 'mobile';
        }
        return 'desktop';
    }

    public getDeviceOS(): IDeviceOS {
        if (typeof navigator === 'undefined') {
            return 'unknown';
        }

        if (/android/i.test(navigator.userAgent)) {
            return 'Android';
        }
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            return 'iOS';
        }
        if (/bot|spider|crawl/i.test(navigator.userAgent)) {
            return 'Bot';
        }

        return 'unknown';
    }
}

const deviceUtility = new DeviceUtility();
export default deviceUtility;
