import React, { FC, useState, Fragment, useContext } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import MuiDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { default as Tree } from '@src/components/Tree';
import { default as LogoIcon } from '@src/components/icons/Logo';
import { GlobalContext } from '@src/provider';
import { INavItem, INavbarData } from './header';

const Drawer = styled(MuiDrawer)`
    .drawer {
        &__container {
            min-width: 80vw;
        }

        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            /* padding: ${(props) => props.theme.spacing(2)}px; */

            &__close-icon {
                position: absolute;
                right: 35px;
                /* top: 10px; */
                color: ${(props) => props.theme.palette.primary.main};
            }

            &__logo-icon {
                font-size: 5rem;
                color: ${(props) => props.theme.palette.primary.main};
                cursor: pointer;
            }
        }
    }
`;

type IProps = {
    navItem: INavItem;
    className?: string;
};

const SideBarDrawer: FC<IProps> = (props) => {
    const { navItem, className } = props;
    const { state } = useContext(GlobalContext);
    const { headerComponent } = state;
    const [open, setOpen] = useState<boolean>(false);
    const handleCloseDrawer = () => {
        setOpen(false);
    };

    const handleClickLogo = () => {
        setOpen(false);
        navigate('/');
    };

    const sideBarData = headerComponent?.navigation?.components?.filter((item) => item.enabled);

    return (
        <Fragment>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setOpen(true)}
                className={className}
            >
                <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <List className="drawer__container">
                    <header className="drawer__header">
                        <LogoIcon className="drawer__header__logo-icon" onClick={handleClickLogo} />
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => setOpen(false)}
                            className="drawer__header__close-icon"
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </header>
                    <Divider />
                    <Fragment>
                        {sideBarData
                            ?.sort((a, b) => a.order - b.order)
                            .map((item) => {
                                if (!item.hasChild) {
                                    return (
                                        <Tree
                                            key={item.name}
                                            name={item.name}
                                            href={item.path}
                                            onClick={handleCloseDrawer}
                                        />
                                    );
                                }
                                return (
                                    <Tree key={item.name} name={item.name}>
                                        {navItem[item.id]
                                            .slice(0, item?.props?.limit || navItem[item.id].length)
                                            .map((nav: INavbarData) => (
                                                <Tree
                                                    key={nav.name}
                                                    name={nav.name}
                                                    href={nav.slug}
                                                    onClick={handleCloseDrawer}
                                                />
                                            ))}
                                    </Tree>
                                );
                            })}
                    </Fragment>
                </List>
            </Drawer>
        </Fragment>
    );
};

export default SideBarDrawer;
