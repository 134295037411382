import React, { FC, useContext } from 'react';
import { GlobalContext } from '@src/provider';
import { Tree } from '@components';

const Recruitment: FC = () => {
    const { state } = useContext(GlobalContext);
    const { recruitment } = state;
    if (!recruitment.enabled || recruitment.item.length < 1) {
        return null;
    }
    return (
        <Tree name={recruitment.name} defaultOpen>
            {recruitment.item
                .filter((item) => item.enabled)
                .map((item) => {
                    return <Tree key={item.name} name={item.name} href={item.link} targetBlank />;
                })}
        </Tree>
    );
};

export default Recruitment;
