import React, { FC, useState, Fragment, useContext } from 'react';
import { navigate } from 'gatsby';
import Button from '@material-ui/core/Button';
import styled from '@emotion/styled';
import { GlobalContext } from '@src/provider';
import { INavComponent } from '@src/provider/provider';
import { INavItem } from './header';
import { default as NavBarMenu } from './NavBarMenu';

const Container = styled.nav`
    .nav-item {
        margin-right: ${(props) => props.theme.spacing(1)}px;
    }
`;

type IProps = {
    navItem: INavItem;
    className?: string;
};

const NavBar: FC<IProps> = (props) => {
    const { navItem, className } = props;
    const { state } = useContext(GlobalContext);
    const { headerComponent } = state;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [currentData, setCurrentData] = useState<INavComponent | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, item: INavComponent) => {
        event.stopPropagation();
        event.preventDefault();
        setCurrentData({ ...item });
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setCurrentData(null);
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>, item: INavComponent) => {
        if (!item.hasChild) {
            navigate(item.path);
        } else {
            handleOpenMenu(event, item);
        }
    };

    const navBarData = headerComponent?.navigation?.components?.filter((item) => item.enabled);

    return (
        <Fragment>
            <Container className={className}>
                {navBarData
                    ?.sort((a, b) => a.order - b.order)
                    ?.map((item) => {
                        return (
                            <Button
                                key={item.id}
                                aria-controls={item.id}
                                aria-haspopup={item.hasChild}
                                color="inherit"
                                onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event, item)}
                                className="nav-item"
                            >
                                {item.name}
                            </Button>
                        );
                    })}
            </Container>
            {currentData && (
                <NavBarMenu
                    anchorEl={anchorEl}
                    navBarData={navBarData}
                    currentData={currentData}
                    handleCloseMenu={handleCloseMenu}
                    navItem={navItem}
                />
            )}
        </Fragment>
    );
};

export default NavBar;
