import { IArticleData } from '@src/components/Article/Article';
import { default as localStorageUtility } from './localStorageUtility';
import { default as deviceUtility } from './deviceUtility';

const counter = (a: string[]): Typing.IKeyValue<string, number>[] => {
    const obj = {};
    for (let i = 0; i < a.length; i++) {
        obj[a[i]] = 1 + (obj[a[i]] || 0);
    }

    const arr: Typing.IKeyValue<string, number>[] = [];
    for (let key in obj) {
        arr.push({ key, value: obj[key] });
    }

    return arr;
};

const removeEmptyOrSlash = (value: string): string => {
    const result = value.replace(/( |\/)/g, '');
    return result;
};

const resizeTarget = (target: Typing.IReSizeTarget): string => {
    const targetValue: Typing.IDictionary<Typing.IReSizeTarget, string> = {
        facebook: `52.356%`,
        youtube: `57%`,
    };
    return targetValue[target];
};

const mappingOgUrl = (siteUrl: string, slug: string, pathPrefix: string) => {
    if (!slug) {
        return new URL(pathPrefix || '/', siteUrl).toString();
    }

    const mappingSlug = slug[0] !== '/' ? `/${slug}` : slug;

    if (pathPrefix && pathPrefix !== '/') {
        return new URL(`${pathPrefix}${mappingSlug}`, siteUrl).toString();
    }

    return new URL(slug, siteUrl).toString();
};

const mappingMdxToArticleData = (edges: Typing.MdxEdge[]): IArticleData[] => {
    const result: IArticleData[] = edges.map(({ node }) => {
        return {
            bannerPhoto: node.frontmatter?.bannerPhoto?.childImageSharp?.fluid?.src || null,
            title: node.frontmatter.title,
            date: node.frontmatter.date,
            authorPhoto: node.frontmatter?.authorPhoto?.childImageSharp?.fluid?.src || null,
            author: node.frontmatter.author,
            content: node?.excerpt || null,
            tags: node.frontmatter?.tags || [],
            link: node.fields.slug,
        };
    });

    return result;
};

const getFileExtension = (filename: string): string => {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : '';
};

export {
    localStorageUtility,
    deviceUtility,
    counter,
    removeEmptyOrSlash,
    resizeTarget,
    mappingOgUrl,
    mappingMdxToArticleData,
    getFileExtension,
};
