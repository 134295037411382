import React, { FC, Fragment, useState } from 'react';
import Fab from '@material-ui/core/Fab';
import MuiDrawer from '@material-ui/core/Drawer';
import CodeIcon from '@material-ui/icons/Code';
import CloseIcon from '@material-ui/icons/Close';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { default as TableOfContent } from './TableOfContent';
import { default as Recruitment } from './Recruitment';
import { ITableOfContents } from './blogToolbar';

const BlockContainer = styled.nav<{ trigger: boolean }>`
    position: fixed;
    left: 65vw;
    top: ${(props) => (props.trigger ? '8px' : '20vh')};
    overflow-y: scroll;
    width: 400px;
    max-height: 100vh;
    ${(props) => props.theme.breakpoints.down('sm')} {
        display: none;
    }
    background-color: ${(props) => props.theme.palette.background.default};
    box-shadow: ${(props) => (props.trigger ? props.theme.shadows[4] : 'none')};
    border-radius: 2%;
    &::-webkit-scrollbar {
        display: none;
    }
    transition: 1s;
`;

const IconContainer = styled.nav`
    z-index: ${(props) => props.theme.zIndex.drawer + 1};
    position: fixed;
    bottom: ${(props) => props.theme.spacing(20)}px;
    right: ${(props) => props.theme.spacing(2)}px;
    ${(props) => props.theme.breakpoints.up('md')} {
        display: none;
    }
    .fab {
        background-color: ${(props) => props.theme.palette.common.white};
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

const Drawer = styled(MuiDrawer)`
    .drawer-list {
        min-height: 100vh;
    }
`;

type IProps = {
    tableOfContents: ITableOfContents;
    className?: string;
};
const BlogToolbar: FC<IProps> = ({ tableOfContents, className }) => {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const handleClickTocIcon = () => {
        setOpen(!open);
    };
    const handleClosedDrawer = () => {
        setOpen(false);
    };
    const trigger =
        typeof window !== `undefined`
            ? useScrollTrigger({
                  target: window,
                  disableHysteresis: true,
                  threshold: 100,
              })
            : false;
    return (
        <Fragment>
            <BlockContainer className={className} trigger={trigger}>
                <TableOfContent tableOfContents={tableOfContents} />
                <Recruitment />
            </BlockContainer>
            <IconContainer>
                <Fab size="large" className="fab" onClick={handleClickTocIcon}>
                    {open ? <CloseIcon /> : <CodeIcon />}
                </Fab>
            </IconContainer>
            <Drawer anchor="top" open={open} onClose={handleClosedDrawer} style={{ zIndex: theme.zIndex.drawer }}>
                <div className="drawer-list">
                    <TableOfContent tableOfContents={tableOfContents} onClickTableContent={handleClosedDrawer} />
                    <Recruitment />
                </div>
            </Drawer>
        </Fragment>
    );
};

export default BlogToolbar;
