import React, { FC, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTransition, animated, config } from 'react-spring';
import styled from '@emotion/styled';

const Container = styled.article`
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    margin: auto;
    ${(props) => props.theme.breakpoints.up('sm')} {
        max-width: 500px;
    }
    ${(props) => props.theme.breakpoints.up('md')} {
        flex-basis: 50%;
        left: 20%;
    }

    .banner-img {
        box-shadow: ${(props) => props.theme.shadows[10]};
        border-top-left-radius: 25%;
        margin-right: 10px;
        background-size: contain;
        background-position: center;
        will-change: opacity;
        position: absolute;
        width: 90%;
        height: auto;
        margin: auto;
        ${(props) => props.theme.breakpoints.up('sm')} {
            width: 100%;
        }
    }
`;

const BannerCarousel: FC = () => {
    const { allFile } = useStaticQuery<Typing.Query>(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "images/landingPageBanner" } }) {
                edges {
                    node {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                            id
                        }
                    }
                }
            }
        }
    `);

    const slides = allFile?.edges?.map(({ node }) => {
        return {
            id: node.childImageSharp.id,
            url: node.childImageSharp.fluid.src,
            fluid: node.childImageSharp.fluid,
        };
    });

    const [slidesIndex, setSlidesIndex] = useState<number>(0);
    const transitions = useTransition(slides[slidesIndex], (item) => item.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    });

    useEffect(() => {
        const slideInit = setInterval(() => setSlidesIndex((state) => (state + 1) % slides.length), 3000);
        return () => {
            clearInterval(slideInit);
        };
    }, []);
    return (
        <Container>
            {transitions.map(({ item, props, key }) => (
                <animated.img key={key} className="banner-img" style={props} src={item.url} alt={key} />
            ))}
        </Container>
    );
};

export default BannerCarousel;
