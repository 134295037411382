import React, { FC, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import dayjs from 'dayjs';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { GlobalContext } from '@src/provider';
import { ISocialMediaName } from '@src/provider/provider';
import { default as LogoIcon } from '@src/components/icons/Logo';
import styled from '@emotion/styled';

const Container = styled.footer`
    background-color: ${(props) => props.theme.palette.primary.main};
    background: ${(props) => props.theme.palette.linearGradient || null};

    .info {
        height: 150px;
        color: ${(props) => props.theme.palette.common.white};
        display: flex;

        &__left-side {
            background-color: ${(props) => props.theme.palette.common.white};
            color: ${(props) => props.theme.palette.primary.main};
            border-bottom-right-radius: 200px;
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            ${(props) => props.theme.breakpoints.down('xs')} {
                display: none;
            }
        }

        &__right-side {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .bottom-info {
        color: ${(props) => props.theme.palette.common.white};
        display: flex;
        justify-content: center;
        padding-bottom: 3px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid ${(props) => props.theme.palette.primary.dark};
        flex-direction: column;

        &__text {
            font-weight: bold;
            &__item {
                margin-right: ${(props) => props.theme.spacing(1)}px;
            }
        }
    }
`;

const SocialMediaIconButton = styled(IconButton)`
    color: inherit;
    display: block;
`;

const SocialMediaIcon: FC<{ socialMediaName: ISocialMediaName }> = ({ socialMediaName }) => {
    switch (socialMediaName) {
        case 'facebook':
            return <FacebookIcon />;
        case 'github':
            return <GitHubIcon />;
        case 'youtube':
            return <YouTubeIcon />;
        case 'linkedin':
            return <LinkedInIcon />;
        default:
            return <LogoIcon />;
    }
};

const Footer: FC = () => {
    const { site } = useStaticQuery<Typing.Query>(graphql`
        query {
            site {
                siteMetadata {
                    deploy {
                        version
                    }
                }
                buildTimeZone
            }
        }
    `);
    const { state } = useContext(GlobalContext);
    const { socialMediaPath } = state;
    const socialMedia: string[] = Object.keys(socialMediaPath);
    const handleClickIcon = (socialMediaName: ISocialMediaName) => {
        window.open(socialMediaPath[socialMediaName], '_blank', 'noopener');
    };

    return (
        <Container>
            <section className="info">
                {/* <aside className="info__left-side" /> */}
                <aside className="info__right-side">
                    {socialMedia?.map((key: ISocialMediaName) => {
                        return (
                            <SocialMediaIconButton key={key} onClick={() => handleClickIcon(key)}>
                                <SocialMediaIcon socialMediaName={key} />
                            </SocialMediaIconButton>
                        );
                    })}
                </aside>
            </section>
            <section className="bottom-info">
                <Typography align="center" variant="subtitle1">
                    © {dayjs().get('year')} 91APP, Inc. 版權所有
                </Typography>
                <Typography align="center" variant="overline">
                    {`＊ Version ${site.siteMetadata.deploy.version} ＊ ${site.buildTimeZone}`}
                </Typography>
            </section>
        </Container>
    );
};

export default Footer;
