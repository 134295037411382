import React, { FC, useContext } from 'react';
import { GlobalContext } from '@src/provider';
import styled from '@emotion/styled';
import { default as LimitLine } from '@src/components/LimitLine';
import { default as BannerCarousel } from './BannerCarousel';

const Container = styled.main`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    min-height: 300px;
    background-color: ${(props) => props.theme.palette.primary.main};
    background: ${(props) => props.theme.palette.linearGradient || null};
    border-bottom-right-radius: 200px;
    position: absolute;
    top: 0;
    left: 0;
    ${(props) => props.theme.breakpoints.up('md')} {
        width: 75%;
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
        width: 70%;
    }

    .banner-info {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &__container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex-grow: 0;
            flex-basis: 100%;
            max-width: 100%;
            /* margin: auto; */
            color: ${(props) => props.theme.palette.common.white};
            padding-left: ${(props) => props.theme.spacing(3)}px;
            padding-right: ${(props) => props.theme.spacing(3)}px;
            ${(props) => props.theme.breakpoints.up('md')} {
                flex-basis: 50%;
                max-width: 50%;
                padding-left: ${(props) => props.theme.spacing(6)}px;
                padding-right: 0;
            }
        }

        &__title {
            font-size: 2rem;
            font-weight: bold;
            text-align: center;
            ${(props) => props.theme.breakpoints.up('sm')} {
                font-size: 2.75rem;
            }
        }

        &__content {
            font-size: 1.75rem;
            text-align: center;
        }
    }
`;

const LandingPageBanner: FC = () => {
    const { state } = useContext(GlobalContext);
    const { landingPageTemplateData } = state;
    const { banner } = landingPageTemplateData;
    return (
        <Container>
            <section className="banner-info">
                <article className="banner-info__container">
                    <h3 className="banner-info__title">
                        <LimitLine limitLines={2} lineHeight="1.5" fontSize="inherit">
                            {banner?.title}
                        </LimitLine>
                    </h3>
                    <div className="banner-info__content">
                        <LimitLine
                            limitLines={5}
                            lineHeight="1.5"
                            fontSize="inherit"
                            dangerouslySetInnerHTML={{ __html: banner?.content }}
                        ></LimitLine>
                    </div>
                </article>
                <BannerCarousel />
            </section>
        </Container>
    );
};

export default LandingPageBanner;
