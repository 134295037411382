import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { mappingOgUrl } from '@src/util';

interface IProps {
    description?: string;
    lang?: string;
    meta?: any[];
    title?: string;
    ogImage?: string;
    slug?: string;
}
const SEO: FC<IProps> = (props) => {
    const { description, lang, meta, title, ogImage, slug } = props;
    const { site, allImageSharp } = useStaticQuery<Typing.Query>(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        deploy {
                            site_url
                            path_prefix
                        }
                    }
                }
                allImageSharp(filter: { fluid: { originalName: { eq: "91APPTechBlogLogo.png" } } }) {
                    nodes {
                        fluid {
                            src
                        }
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const defaultImage = allImageSharp.nodes[0].fluid.src;

    const mappingOgImage = (): string => {
        const src = ogImage || defaultImage;
        return new URL(src, site.siteMetadata.deploy.site_url).toString();
    };

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title || site.siteMetadata.title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `viewport`,
                    content: `user-scalable = no, width=device-width`,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:url`,
                    content: mappingOgUrl(
                        site.siteMetadata.deploy.site_url,
                        slug,
                        site.siteMetadata.deploy.path_prefix
                    ),
                },
                {
                    property: `og:locale`,
                    content: `zh_TW`,
                },
                {
                    property: `og:title`,
                    content: title || site.siteMetadata.title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: mappingOgImage(),
                },
                {
                    property: `og:image:width`,
                    content: `1200`,
                },
                {
                    property: `og:image:height`,
                    content: `628`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        >
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        </Helmet>
    );
};

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

export default SEO;
