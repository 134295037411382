import React, { FC } from 'react';
import styled from '@emotion/styled';

type IProps = {
    src: string;
    title: string;
};

const Container = styled.div`
    position: relative;
    padding-bottom: 57%;
    overflow: hidden;
    box-shadow: ${(props) => props.theme.shadows[10]};
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 20px;

    .iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const getYoutubeId = (url: string): string => {
    const regExp: RegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
};

const Video: FC<IProps> = ({ src, title }) => {
    const youtubeId = getYoutubeId(src);
    const path = `//www.youtube.com/embed/${youtubeId}`;
    return (
        <Container>
            <iframe
                src={path}
                className="iframe"
                title={title}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
                height="360"
            />
        </Container>
    );
};
export default Video;
