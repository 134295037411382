import React, { FC, useEffect, useState } from 'react';
import { useTransition, animated, config } from 'react-spring';
import styled from '@emotion/styled';

type ISlidesData = {
    id: number | string;
    fluid: Typing.ImageSharpFluid;
};

type IProps = {
    data: ISlidesData[];
    className?: string;
};

const Container = styled.div`
    position: relative;
    .slide-img {
        background-size: contain;
        background-position: center;
        will-change: opacity;
        position: absolute;
        width: 100%;
        height: 100%;
    }
`;

const Slides: FC<IProps> = ({ data, className }) => {
    const [slidesIndex, setSlidesIndex] = useState<number>(0);
    const transitions = useTransition(data[slidesIndex], (item) => item.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    });

    useEffect(() => {
        const slideInit = setInterval(() => setSlidesIndex((state) => (state + 1) % data.length), 3000);
        return () => {
            clearInterval(slideInit);
        };
    }, []);
    return (
        <Container className={className}>
            {transitions.map(({ item, props, key }) => (
                <animated.img
                    key={key}
                    className="slide-img"
                    style={props}
                    src={item.fluid.src}
                    alt={item.fluid.originalName}
                />
            ))}
        </Container>
    );
};

export default Slides;
